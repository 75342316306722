import { defineStore } from 'pinia';
import { add, isAfter, sub } from 'date-fns';
import {extendVolunteerSession} from "~/composables/queries/session";

export const useSessionStore = defineStore(
  'session',
  () => {
    const router = useRouter();
    const config = useRuntimeConfig();

    const defaultSessionDuration = config.public.sessionDuration ? parseInt(config.public.sessionDuration) : 30;

    const isIdle = ref(false);
    const timer = ref(defaultSessionDuration);
    const togglePopup = ref(false);
    const timerActive = ref(false);
    const sessionDuration = ref(defaultSessionDuration);
    const sessionTimeout = ref(add(new Date(), { seconds: sessionDuration.value }).getTime());

    const setIsIdle = (value: boolean) => {
      isIdle.value = value;
    };

    const resetTimer = (value = defaultSessionDuration) => {
      sessionDuration.value = value;
      sessionTimeout.value = add(new Date(), { seconds: sessionDuration.value }).getTime();
      togglePopup.value = false;
      timer.value = value;
    };

    const extend = async () => {
      const resp = await extendSession();
      if (!resp.success) {
        timeout();
      } else {
        resetTimer(resp.data.sessionDuration);
      }
    };

    const extendVolunteer = async () => {
      const resp = await extendVolunteerSession();
      if (!resp.success) {
        timeout();
      } else {
        console.log(resp?.token);
        localStorage.setItem('food-pantry-token', resp?.token);
        resetTimer(parseInt(config.public.sessionDuration));
      }
    };

    const timeInterval = ref();
    const startCountdownTimer = () => {
      if (!timeInterval.value) {
        timerActive.value = true;
        timeInterval.value = setInterval(() => {
          timer.value -= 1;
          const now = new Date();
          // const thirtySecondsFromTimeout = sub(sessionTimeout.value, { seconds: 30 });
          const fiveMinsFromTimeout = sub(sessionTimeout.value, { seconds: 300 });
          if (isAfter(now, fiveMinsFromTimeout)) {
            togglePopup.value = true;
          }
        }, 1000);
      }
    };

    const stopCountdownTimer = () => {
      timerActive.value = false;
      togglePopup.value = false;
      clearInterval(timeInterval.value);
      timeInterval.value = null;
    };

    const timeout = () => {
      stopCountdownTimer();
      clearUserSession();
      const route = useRoute()
      if (route.fullPath.includes('/fp/')) {
        router.push(`/fp/${route.params.id}`);
      } else {
        router.push('/?timeout=true');
      }
    };

    const isAdmin = ref(false);
    const setIsAdmin = (value: boolean) => {
      isAdmin.value = value;
    };

    return {
      isIdle,
      setIsIdle,
      togglePopup,
      timer,
      resetTimer,
      startCountdownTimer,
      stopCountdownTimer,
      timerActive,
      extend,
      timeout,
      sessionTimeout,
      isAdmin,
      setIsAdmin,
      extendVolunteer,
    };
  },
  {
    persist: true,
  }
);
