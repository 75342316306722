export enum EnumStepComponentType {
  Default = 'Default',
  HouseholdMember = 'HouseholdMember',
  Consent = 'Consent',
}

export enum EnumAssistanceRequestStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Declined = 'Declined',
  Accepted = 'Accepted',
  ApprovalPending = 'ApprovalPending',
  ReturnedToApplicant = 'ReturnedToApplicant',
  ApprovedClosed = 'ApprovedClosed',
  Rescinded = 'Rescinded',
  ApprovedReceivedByFinance = 'ApprovedReceivedByFinance',
  ApprovedFundingSent = 'ApprovedFundingSent',
  Abandoned = 'Abandoned',
  Expired = 'Expired',
  UserDeleted = 'UserDeleted',
}

export enum EnumAssistanceRequestStatusUserFriendly2 {
  Draft = 'Draft',
  Submitted = 'Acceptance Pending',
  Approved = 'Approved',
  Declined = 'Declined',
  Accepted = 'Accepted',
  ApprovalPending = 'Approval Pending',
  ReturnedToApplicant = 'Returned to Applicant',
  ApprovedClosed = 'Closed',
  Rescinded = 'Rescinded',
  ApprovedReceivedByFinance = 'Received by Finance',
  ApprovedFundingSent = 'Payment Sent',
  Abandoned = 'Abandoned',
  UserDeleted = 'User Deleted',
}

export enum EnumLedgerTransactionType {
  RequestApprovalPending = 'RequestApprovalPending',
  RequestApproved = 'RequestApproved',
  RequestFunded = 'RequestFunded',
  RequestRescinded = 'RequestRescinded',
  FundDecreased = 'FundDecreased',
  FundIncreased = 'FundIncreased',
  FundingSourceDecreased = 'FundingSourceDecreased',
  FundingSourceIncreased = 'FundingSourceIncreased',
}

export enum EnumAssistanceTypeCategory {
  Housing = 'Housing',
  UtilGas = 'UtilGas',
  UtilElect = 'UtilElect',
  UtilWater = 'UtilWater',
}
export enum EnumAssistanceRequestStatusUserFriendly {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Declined = 'Declined',
  Accepted = 'Accepted',
  ApprovalPending = 'Approval Pending',
  ReturnedToApplicant = 'Returned to Applicant',
  ApprovedClosed = 'Closed',
  Rescinded = 'Rescinded',
  ApprovedReceivedByFinance = 'Received by Finance',
  ApprovedFundingSent = 'Payment Sent',
  Abandoned = 'Abandoned',
  Expired = 'Expired',
  UserDeleted = 'User Deleted',
}

export enum EnumApplicantStatusUserFriendly {
  Draft = 'Not Yet Submitted',
  Submitted = 'Submitted / In Progress',
  Approved = 'Submitted / In Progress',
  Declined = 'Declined',
  Accepted = 'Submitted / In Progress',
  ApprovalPending = 'Submitted / In Progress',
  ReturnedToApplicant = 'Returned to Applicant',
  ApprovedClosed = 'Closed',
  Rescinded = 'Declined',
  ApprovedReceivedByFinance = 'Submitted / In Progress',
  ApprovedFundingSent = 'Submitted / In Progress',
  Abandoned = 'Abandoned',
  Expired = 'Expired',
  UserDeleted = 'User Deleted',
}

export enum EnumArStatusUserFriendly {
  Draft = 'Not Yet Submitted',
  Submitted = 'Submitted / In Progress',
  Approved = 'Submitted / In Progress',
  Declined = 'Declined',
  Accepted = 'Submitted / In Progress',
  ApprovalPending = 'Submitted / In Progress',
  ReturnedToApplicant = 'Returned to Applicant',
  ApprovedClosed = 'Approved',
  Rescinded = 'Declined',
  ApprovedReceivedByFinance = 'Submitted / In Progress',
  ApprovedFundingSent = 'Submitted / In Progress',
  Abandoned = 'Abandoned',
  Expired = 'Expired',
  UserDeleted = 'User Deleted',
}

export enum EnumFundingSourceConfiguration {
  Ask = 1,
  Excluded = 2,
  Required = 3,
  NotAsked = 4,
}

export const langFullAbbr = {
  en: 'en-US',
  es: 'es-ES',
};
export enum EnumAppointmentStatusUserFriendly {
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  CancelledByApplicant = 'Cancelled',
  CancelledByCaseworker = 'Cancelled',
  Complete = 'Completed',
  NoShow = 'No Show',
}
