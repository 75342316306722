import {FoodPantry} from "~/composables/models/FoodPantry";

export const extendSession = () => {
  const cookie = useCookie('applicant-token');
  const config = useRuntimeConfig();
  return $fetch<{ success: boolean; data: { sessionDuration: number } }>(`/api/v1/applicants/auth/extend-session`, {
    baseURL: config.public.apiBase,
    headers: {
      Authorization: `Bearer ${cookie.value}`,
    },
    method: 'GET',
  });
};

export const extendVolunteerSession = () => {
  return $fetch<any>(`/pantries/refreshtoken `, {
    ...FoodPantry.getToken(),
    method: 'POST',
    baseURL: FoodPantry.getBaseUrl(),
  });
};
