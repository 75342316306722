import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query';
// Nuxt 3 app aliases
import { useState } from '#app';
import { useSessionStore } from '~/composables/stores/session';
export default defineNuxtPlugin((nuxtApp) => {
  // Modify your Vue Query global settings here
  const useSession = useSessionStore();
  const applicationStore = useApplicationStore();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ingore because we don't need to type errors
  const onError = async ({ error, response, context }: any) => {
    console.error('onError', response, context, error);

    // we check on the chat redirect to give the possibility to the user to login without go the home page
    if (response?.status === 401 && applicationStore.linkRedirect === '') {
      useSession.timeout();
    }
    if (response?.status === 404) {
      useSession.timeout();
    } else {
      // const apiErrorMessage = getErrorMessage(response);
      // const errorMessage = apiErrorMessage || `Error ${response.status}.  Please try again or contact your local Salvation Army.`;
      //
      // toastError('Error', errorMessage);
    }
  };

  const onSuccess = async () => {
    useSession.resetTimer();
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        // onError,
      },
      queries: {
        staleTime: 5000,
        refetchOnWindowFocus: false,
        // retryDelay: (attemptIndex) => Math.min(3000 * 2 ** attemptIndex, 30000),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ingore because we don't need to type errors
        onError,
        onSuccess,
      },
    },
  });
  const options: VueQueryPluginOptions = { queryClient };

  nuxtApp.vueApp.use(VueQueryPlugin, options);
  const vueQueryClient = useState('@tanstack/vue-query');

  if (process.server) {
    nuxtApp.hooks.hook('app:rendered', () => {
      vueQueryClient.value = dehydrate(queryClient);
    });
  }

  if (process.client) {
    nuxtApp.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryClient.value);
    });
  }
});
