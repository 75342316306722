import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.environment,
    release: 'sahelp-applicant@' + config.public.clientVersion,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
      }),
      new Sentry.Replay(),
    ],
    enabled: config.public.sentryEnabled,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      const error = hint.originalException as Error & { response?: { status?: number } };
      if (error && error.name === 'FetchError') {
        if (error.response && (error.response.status === 400 || error.response.status === 401)) {
          return null;
        }
      }
      return event;
    },
  });
});
