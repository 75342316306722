import IdleJs from 'idle-js';

export default defineNuxtPlugin(() => {
  const useSession = useSessionStore();

  const onIdle = () => {
    useSession.setIsIdle(true);
    console.log('idle');
  };

  const onActive = () => {
    useSession.setIsIdle(false);
    console.log('active');
  };

  const idle = new IdleJs({
    idle: 15 * 60 * 1000, // idle time in ms
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
    onIdle, // callback function to be executed after idle time
    onActive, // callback function to be executed after back form idleness
    // onHide: () => console.log('hide'), // callback function to be executed when window become hidden
    // onShow: () => console.log('show'), // callback function to be executed when window become visible
    keepTracking: true, // set it to false if you want to be notified only on the first idleness change
    startAtIdle: false, // set it to true if you want to start in the idle state
  });
  idle.start();
});
