import { ApplicantFormItem, SelectFieldItem, DropDownListItem } from './custom-types';
import { Ref } from 'vue';
import { FormRequestDtoItem, FormSchema } from './composables/types/Common';
import { parse, format } from 'date-fns';

export const globalFetchOptions = ({ context }: { context: string }) => {
  const config = useRuntimeConfig();
  return {
    onResponseError: ({ response }) => {
      throw { context, response };
    },
    onRequestError: ({ error }) => {
      throw { context: 'Sorry, we are presently offline for maintenance. Please try again later.', error };
    },
    onResponse() {},
    baseURL: config.public.apiBase,
    params: { co_la: process.client === true ? navigator.language : 'en' },
  };
};

export const globalFetchOptionsPrivate = ({ context }: { context: string }) => {
  const cookie = useCookie('applicant-token');

  return {
    ...globalFetchOptions({ context }),
    headers: {
      Authorization: `Bearer ${cookie.value}`,
    },
  };
};

export const friendlyMessages = {
  expiredSession:
    'It appears that your previous application session expired.  Please answer the questions below and on the following screen and you will be able to complete your existing application or begin another.',
};

export const getErrorMessage = (response) => {
  const parts = getErrorMessageParts(response);
  return parts !== '' ? parts[2] : parts;
};
export const getErrorMessageParts = (response) => {
  if (response?._data?.errors && Array.isArray(response?._data?.errors)) {
    return response?._data?.errors[0]?.split('|');
  }
  return '';
};

export const getFormSchema = (formSchema: Ref<FormSchema>) => {
  if (!formSchema || !formSchema.value || !formSchema.value.formDtos || !formSchema.value.selectFieldDtos) {
    return null;
  }
  let schema = [];

  formSchema.value.formDtos.forEach((formItem) => {
    const $formkit = formItem.htmlDataType || 'text';
    let isText = false;
    if ($formkit === 'text') {
      isText = true;
    }

    let options = [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ];
    if ($formkit === 'select') {
      const selectField = formSchema.value.selectFieldDtos.find((item: SelectFieldItem) => {
        return item.fieldHtmlId === formItem.fieldHtmlId;
      });
      if (selectField) {
        options = selectField.dropDownList.map((item: DropDownListItem) => {
          return {
            value: item.itemValue,
            label: item.itemText,
          };
        });
      } else {
        // TODO: remove prior to launch
        alert('No select options provided for select input! ' + formItem.fieldHtmlId);
      }
    }

    const validationPrep = {
      required: formItem.isRequired,
      maxLength: isText ? formItem.htmlMaxLength : undefined,
    };
    const validations = Object.keys(validationPrep).map((key) => {
      if (!validationPrep[key]) {
        return null;
      }
      if (key === 'maxLength') {
        return `length:0,${validationPrep[key]}`;
      }
      return key;
    });
    const schemaItem = {
      $formkit,
      name: formItem.fieldName,
      label: formItem.fieldLabel,
      id: formItem.fieldHtmlId,
      validation: validations.join('|'),
      options,
      classes: {
        outer: convertToSpan(formItem.htmlFieldSize),
      },
    };
    schema = [...schema, schemaItem];
  });
  return schema;
};

export const formDataToSchema = (formSchema: Ref<FormSchema>, formData: FormData) => {
  const items: FormRequestDtoItem[] = [];
  if (!formSchema || !formSchema.value || !formSchema.value.formDtos || !formSchema.value.selectFieldDtos) {
    return items;
  }

  const formDataKeys = Object.keys(formData);

  formSchema.value.formDtos.forEach((formItem) => {
    const key = formDataKeys.find((k) => k === formItem.fieldHtmlId);
    if (key) {
      let val = formData[key];
      if (Array.isArray(val)) val = val[0];

      if (formItem.sqlDataType === 'bit') {
        if (val === 'true') val = '1';
        else if (val === 'false') val = '0';
        else val = null;
      } else if (!formItem.isRequired && (val === undefined || val === '')) {
        val = null;
      }

      items.push({
        fieldHtmlId: key,
        value: val,
      });
    }
  });
  return items;
};

export const apiDataToFormData = (
  formSchema: Ref<{ formDtos: ApplicantFormItem[]; selectFieldDtos: SelectFieldItem[] }>,
  apiData: FormRequestDtoItem[]
) => {
  const items: FormRequestDtoItem[] = [];
  if (!formSchema || !formSchema.value || !formSchema.value.formDtos || !formSchema.value.selectFieldDtos) {
    return items;
  }

  formSchema.value.formDtos.forEach((formItem) => {
    const item = apiData.find((k) => k.fieldHtmlId === formItem.fieldHtmlId);
    if (item) {
      let val = item.value;
      if (Array.isArray(val)) val = val[0];

      if (val === null) val = undefined;

      if (formItem.sqlDataType === 'bit') {
        if (val === '1') val = 'true';
        else if (val === '0') val = 'false';
      }

      if (formItem.htmlDataType === 'date' && val) {
        const dt = parse(val, 'M/d/y hh:mm:ss aaa', new Date());
        if (!isNaN(dt.getTime())) {
          val = format(dt, 'yyyy-MM-dd');
        }
      }

      items.push({
        fieldHtmlId: item.fieldHtmlId,
        value: val,
      });
    }
  });
  return items;
};

const convertToSpan = (value = 50) => {
  const span = Math.round(value / 5);
  if (span < 5) {
    return `sm:col-span-4 col-span-8`;
  }
  if (span > 5 && span < 8) {
    return `sm:col-span-4 col-span-8`;
  }
  return `sm:col-span-8 col-span-8`;
};
export const formatPhoneNumber = (input: string): string | null => {
  // Remove any non-numeric characters from the input
  const cleanedInput = input.replace(/\D/g, '');

  // Check if the cleaned input has exactly 10 digits (standard for US phone numbers)
  if (cleanedInput.length !== 10) {
    return null; // or throw an error or handle it as you see fit
  }

  // Extract parts of the number
  const areaCode = cleanedInput.substring(0, 3);
  const centralOfficeCode = cleanedInput.substring(3, 6);
  const lineNumber = cleanedInput.substring(6, 10);

  // Format and return
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};
