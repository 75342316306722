import { defineStore } from 'pinia';
import { FormSchema } from '~/composables/types/Common';
import { globalFetchOptionsPrivate } from '~/utils';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore(
  'user',
  () => {
    const zipCode = ref('');
    const securityToken = ref('');
    const refreshSecurityToken = ref('');
    const expireMinutes = ref<number | undefined>();
    const refreshExpireMinutes = ref<number | undefined>();
    const emailAddress = ref('');
    const campaignId = ref<number | undefined>();
    const applicantId = ref<number | undefined>();
    const prayerRequestMessage = reactive({
      prayerRequestText: '',
      allowContact: false,
    });
    const assistanceRequestId = ref<number | undefined>();
    const assistanceType = ref('');
    const globalStartOverMessage = ref('');

    const userIpData = ref({
      remoteIP: '',
      locaIP: '',
      hash: '',
      lastUpdated: '',
    });
    // fetch the user's data from an API
    const fetchUser = async () => {
      if (
        userIpData.value.lastUpdated === '' ||
        new Date().getTime() - new Date(userIpData.value.lastUpdated).getTime() > 60000
      ) {
        const config = useRuntimeConfig();
        const _ = await $fetch<{ data: { remoteIP: string; locaIP: string; hash: string } }>(
          `/api/v1/applicants/get-ip-addresses`,
          {
            method: 'get',
            baseURL: config.public.apiBase,
          }
        );

        userIpData.value.remoteIP = _?.data?.remoteIP ?? '';
        userIpData.value.locaIP = _?.data?.locaIP ?? '';
        userIpData.value.hash = _?.data?.hash ?? '';
        userIpData.value.lastUpdated = new Date().toISOString();
      }
    };

    function clear() {
      zipCode.value = '';
      securityToken.value = '';
      refreshSecurityToken.value = '';
      emailAddress.value = '';
      expireMinutes.value = undefined;
      refreshExpireMinutes.value = undefined;
      campaignId.value = undefined;
      applicantId.value = undefined;
      prayerRequestMessage.prayerRequestText = '';
      prayerRequestMessage.allowContact = false;
      assistanceRequestId.value = undefined;
      assistanceType.value = '';
      globalStartOverMessage.value = '';
    }

    return {
      zipCode,
      securityToken,
      expireMinutes,
      refreshSecurityToken,
      refreshExpireMinutes,
      emailAddress,
      clear,
      campaignId,
      applicantId,
      prayerRequestMessage,
      assistanceRequestId,
      assistanceType,
      globalStartOverMessage,
      fetchUser,
      userIpData,
    };
  },
  {
    persist: true,
  }
);
