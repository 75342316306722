export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('apollo:error', ({ graphQLErrors }) => {
    const useSession = useSessionStore();
    if (graphQLErrors && graphQLErrors.length) {
      // @ts-ignore
      const graphErrorMessage = graphQLErrors[0]?.extensions?.internal?.response?.body?.error?.Message;

      if (graphErrorMessage) {
        toastError('Error', graphErrorMessage);
        console.error(graphErrorMessage);
      }

      // @ts-ignore
      if (graphQLErrors[0]?.extensions?.internal?.response?.status === 401) {
        useSession.timeout();
      }
    }
  });
});
