import { assistanceRequestPrefix, formatDatetoMDY } from '@tsamm-v2/admin-dashboard/utils/helpers';
import { useApplicationStore } from '~/composables/stores/ApplicationStore';
import { useApplicantStore } from '~/composables/stores/applicant';
import { useServiceAreaStore } from '~/composables/stores/service-area';
import { InputTextKeyboardEvent } from '~/custom-types';
import { FormStep } from '../types/RequestForm';
import { DateTime } from 'luxon';

export const customInvalid = (e: InputTextKeyboardEvent) => {
  if (e.currentTarget.validity.patternMismatch) {
    const message = e.currentTarget.getAttribute('data-val-regex') || 'Invalid input';
    e.currentTarget.setCustomValidity(message);
  } else {
    e.currentTarget.setCustomValidity('');
  }
};

export const toastError = (title: string, message: string) => {
  const useErrorHandler = useErrorHandlerStore();
  const fixedTimeout = 1000000;
  useErrorHandler.addToast({
    type: ToastEnum.error,
    title,
    message,
    timeout: fixedTimeout,
  });
};

export const toastSuccess = (title: string, message: string) => {
  const useErrorHandler = useErrorHandlerStore();
  const fixedTimeout = 3000;
  useErrorHandler.addToast({
    type: ToastEnum.success,
    title,
    message,
    timeout: fixedTimeout,
  });
};

export const cleanPhoneNUmber = (phone: string | null) => {
  if (phone === null) return '';

  return phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
};
export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) {
        reject('missing result');
        return;
      }
      resolve(reader.result.toString());
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}

// remove the user token from the cookie and all stored data
export function clearUserSession() {
  const useUser = useUserStore();
  const useServiceArea = useServiceAreaStore();
  const useApplicant = useApplicantStore();
  const applicationStore = useApplicationStore();
  const sessionStore = useSessionStore();

  useUser.clear();
  useServiceArea.clear();
  useApplicant.clear();
  sessionStore.stopCountdownTimer();
  sessionStore.setIsAdmin(false);
  const cookie = useCookie('applicant-token', {
    sameSite: 'strict',
  });
  cookie.value = null;
  applicationStore.setLinkRedirect(``);
}

export function getYouTubeVideoId(url: string) {
  let videoId = '';
  if (url.indexOf('youtube.com/watch?v=') !== -1) {
    videoId = url.split('youtube.com/watch?v=')[1];
    if (videoId.indexOf('&') !== -1) {
      videoId = videoId.substring(0, videoId.indexOf('&'));
    }
  } else if (url.indexOf('youtube.com/embed/') !== -1) {
    videoId = url.split('youtube.com/embed/')[1];
    if (videoId.indexOf('?') !== -1) {
      videoId = videoId.substring(0, videoId.indexOf('?'));
    }
  } else if (url.indexOf('youtu.be/') !== -1) {
    videoId = url.split('youtu.be/')[1];
    if (videoId.indexOf('?') !== -1) {
      videoId = videoId.substring(0, videoId.indexOf('?'));
    }
  }
  return videoId;
}

export const extendSessionHelper = () => {
  const sessionStore = useSessionStore();
  sessionStore.extend().catch((err) => console.error(err));
};

export const formatDate = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);
  const month = dateTime.getMonth() + 1;
  const day = dateTime.getDate();
  const year = dateTime.getFullYear();
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  return `${month}/${day}/${year} ${hours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
};

export const getTimeZone = (dateTimeString: string) => {
  const date = new Date(dateTimeString);

  const timezoneOffsetString = date.toLocaleString('en-US', { timeZoneName: 'short', hour12: false }).split(' ')[2];

  const timezoneAbbreviationMap: { [key: string]: string } = {
    'GMT-12': 'IDL',
    'GMT-11': 'NUT',
    'GMT-10': 'HST',
    'GMT-9': 'AKST',
    'GMT-8': 'PST',
    'GMT-7': 'MST',
    'GMT-6': 'CST',
    'GMT-5': 'EST',
    'GMT-4': 'AST',
    'GMT-3': 'BRT',
    'GMT-2': 'GST',
    'GMT-1': 'CET',
    'GMT+0': 'GMT',
    'GMT+1': 'CET',
    'GMT+2': 'EET',
    'GMT+3': 'MSK',
    'GMT+4': 'AMT',
    'GMT+5': 'PKT',
    'GMT+6': 'BST',
    'GMT+7': 'ICT',
    'GMT+8': 'CST',
    'GMT+9': 'JST',
    'GMT+10': 'AEST',
    'GMT+11': 'AEDT',
    'GMT+12': 'NZDT',
  };

  return timezoneAbbreviationMap[timezoneOffsetString] || timezoneOffsetString;
};

export const downLoadBlob = (blob: Blob, fileName: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // Append the anchor to the body
  a.click(); // Programmatically click the anchor
  document.body.removeChild(a); // Remove the anchor from the body
  URL.revokeObjectURL(url); // Revoke the object URL to free up memory
};

export const globalUseQueryOptions = () => {
  return {
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 0,
    enabled: true,
    retry: 0,
  };
};

export const globalFetchOptions = () => {
  const config = useRuntimeConfig();
  const cookie = useCookie('applicant-token');
  return {
    baseURL: config.public.apiBase + '/api/v1',
    headers: {
      Authorization: `Bearer ${cookie.value}`,
    },
  };
};

export const generateAssistanceRequestId = (id = 0) => {
  const fullId = String(id).padStart(11, '0');
  const formattedId =
    fullId.substring(0, 2) + '-' + fullId.substring(2, 5) + '-' + fullId.substring(5, 8) + '-' + fullId.substring(8);
  return `${assistanceRequestPrefix}${formattedId}`;
};

export const getDateFromDayNumber = (dayNumber: number) => {
  const date = new Date(1901, 0, 1, 11, 59, 59);
  const futureDate = new Date(date);
  futureDate.setDate(date.getDate() + dayNumber);
  return futureDate.toISOString().slice(0, 10);
};

//this is work around : (x.configuration ?? x.Configuration) : reason for this is because the configuration is different in the backend api and the formkit schema in database
// another work around: the first time through app, excluded fieldconfigs return 'Excluded', but upon resuming app, fieldconfigs return 2
export const allFieldsExcludedFromStep = (step: FormStep) =>
  step.fields.every((f) =>
    f.fieldConfigs.some(
      (x) => (x.configuration ?? x.Configuration) === 2 || (x.configuration ?? x.Configuration) === 'Excluded'  || (x.configuration ?? x.Configuration) === 4  || (x.configuration ?? x.Configuration) === 'NotAsked'
    )
  )
    ? true
    : false;

interface Corner {
  lat: number;
  lng: number;
}

interface BoundingBox {
  swCorner: Corner;
  neCorner: Corner;
}
export function createBoundingBox(latitude: number, longitude: number, radius = 17000): BoundingBox {
  const RADIAN_UNIT = 57.2957795;
  const EARTH_RADIUS_IN_KM = 6371.0;

  let radiusInKM: number;
  if (radius >= 1000) {
    radiusInKM = radius / 1000.0;
  } else {
    radiusInKM = radius;
  }

  const latDelta = radiusInKM / EARTH_RADIUS_IN_KM;
  const lonDelta = Math.asin(Math.sin(latDelta) / Math.cos(latitude / RADIAN_UNIT));

  const minLat = latitude - latDelta;
  const maxLat = latitude + latDelta;
  const minLon = longitude - lonDelta;
  const maxLon = longitude + lonDelta;

  return {
    swCorner: { lat: minLat, lng: minLon },
    neCorner: { lat: maxLat, lng: maxLon },
  };
}
// export function toTitleCase(str: unknown): string {
//   return `${str}`.replace(/\w\S*/g, function (txt: string): string {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// }

export function toTitleCase(str: unknown): string {
  if (str === undefined || str === '') {
    return '';
  }

  return `${str}`.replace(/\b\w+\b/g, function (txt: string): string {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
}

export const removeHTMLTags = (str: string) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(str, 'text/html');
  return parsedHtml.body.textContent || '';
};

export const appoloIpCustomHeader = () => {
  const userStore = useUserStore();
  return {
    'sahelp-remote-ip': userStore.userIpData.remoteIP,
    'sahelp-local-ip': userStore.userIpData.locaIP,
    'sahelp-hash': userStore.userIpData.hash,
  };
};

export const formatToMonth = (d: string, format = 'LLL d') => {
  if (d === undefined) return '';
  const parsedDate = DateTime.fromJSDate(new Date(d));
  const { locale } = useI18n();
  return parsedDate.toFormat(format, { locale: locale.value });
};

export const applicationPrefix = 'APP-';
export const generateApplicationId = (id = 0) => {
  const fullId = String(id).padStart(11, '0');
  const formattedId = fullId.substring(0, 5) + '-' + fullId.substring(5);
  return `${applicationPrefix}${formattedId}`;
};

export const minutesToHours = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';

  if (hours > 0) {
    result += hours + ' hour' + (hours > 1 ? 's' : '');
  }

  if (remainingMinutes > 0) {
    if (result) result += ' ';
    result += remainingMinutes + ' minute' + (remainingMinutes > 1 ? 's' : '');
  }

  return result || '0 minutes';
};

export const minutesToHoursSingular = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';

  if (hours > 0) {
    result += hours + ' hour';
  }

  if (remainingMinutes > 0) {
    if (result) result += ' ';
    result += remainingMinutes + ' minute';
  }

  return result || '0 minute';
};

export const checkSaHelpEmail = (email: string) => {
  const config = useRuntimeConfig();
  if (email.includes(config.public.applicantFakeEmail as string)) {
    return 'No Email Address';
  }
  return email;
};

export const getAppointmentNotePrefix = (
  appointmentTypes: string[],
  submittedAt: string | null,
  createdAt: string,
  lang = 'en'
) => {
  const date = submittedAt ?? createdAt;
  if (lang === 'es') {
    const action = submittedAt ? 'envió' : 'comenzó';
    if (appointmentTypes === undefined) return '';

    if (appointmentTypes.length === 0)
      return `Esto se refiere a la solicitud de asistencia que usted ${action} el ${formatDatetoMDY(date)}.`;

    const spanishType = {
      'Utility: Water': 'Servicio: Agua',
      'Utility: Gas/Propane': 'Servicio: Gas',
      'Utility: Electricity': 'Servicio: Electricidad',
      Rent: 'Renta',
      Mortgage: 'Hipoteca',
    };

    if (appointmentTypes.length === 1)
      return `Esto concierne a la solicitud de asistencia para ${
        spanishType[appointmentTypes[0]]
      } que usted ${action} el ${formatDatetoMDY(date)}.`;

    if (appointmentTypes.length > 1)
      return `Esto concierne a la solicitud de asistencia que usted ${action} el ${formatDatetoMDY(
        date
      )} (para ${appointmentTypes.map((_) => spanishType[_]).join(', ')}).`;

    return `Esta es una solicitud de asistencia con ${appointmentTypes.map((_) => spanishType[_]).join(', ')}.`;
  } else {
    const action = submittedAt ? 'submitted' : 'began';

    if (appointmentTypes.length === 0)
      return `This concerns the application for assistance that you ${action} on ${formatDatetoMDY(date)}.`;

    if (appointmentTypes.length === 1)
      return `This concerns the application for ${appointmentTypes[0]} assistance that you ${action} on ${formatDatetoMDY(
        date
      )}.`;

    if (appointmentTypes.length > 1)
      return `This concerns the application for assistance that you ${action} on ${formatDatetoMDY(
        date
      )} (for ${appointmentTypes.join(', ')}).`;

    return `This is a request for assistance with ${appointmentTypes.join(', ')}.`;
  }
};

export const applicantPrefix = 'SS-';
export const generateApplicantId = (id = 0) => {
  const fullId = String(id).padStart(11, '0');
  const formattedId =
    fullId.substring(0, 2) + '-' + fullId.substring(2, 5) + '-' + fullId.substring(5, 8) + '-' + fullId.substring(8);
  return `${applicantPrefix}${formattedId}`;
};

export const dateDiff = (date: string) => {
  if (date === undefined || date === null || date === '') return null;

  // Define the specific date
  const specificDate = DateTime.fromISO(date);

// Get the current date and time
  const now = DateTime.now();

// Calculate the difference in days
  return now.diff(specificDate, 'days').days;
}

export const formatDatetoMDYFromString = (date: string) => {
  if (date === undefined || date === null || date === '')return '';

  const dt = DateTime.fromISO(date);
  return dt.toFormat('M/d/yyyy');
};
export const fromISOToStringUsingTimezone = (date: string, timezone: string, format: string): string => {
  return DateTime.fromISO(date, { zone: 'utc' }).setZone(timezone).toFormat(format);
};
