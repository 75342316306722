import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { Applicant, ApplicantPhone, AutocorrectedApplicant, IsApplicantAutocorrecting } from '~/composables/types/Applicant';
import { CreateRequestOutput } from '~/composables/types/RequestForm';
import { StepFormValues } from '~/composables/types/StepFormValues';
import { SavePersonalDetails } from '../types/PersonalDetails';
import { useMutation } from '@tanstack/vue-query';
// import { get } from 'lodash';

export const useApplicantStore = defineStore(
  'applicant',
  () => {
    const emptyApplicant = ref<Applicant>({
      id: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      // phone_number: '',
      // phone_opted_in: false,
      // phone_validated: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      suffix: '',
      county: '',
      latitude: 0,
      longitude: 0,
      applications: [],
      phones: [],
      addresses: [],
    });

    const autoCorrectModalOpen = ref(false);
    const autocorrectedApplicant = ref<{ [key: string]: boolean }>({
      first_name: false,
      last_name: false,
      middle_name: false,
      address1: false,
      address2: false,
      city: false,
      firstname: false,
      lastname: false,
      landlordname: false,
      landlordaddress: false,
      landlordcity: false,
      lendername: false,
      lenderaddress: false,
      lendercity: false,
      utilityprovidername: false,
      utilityprovideraddress: false,
      utilityprovidercity: false,
      firstName: false,
      lastName: false,
      monetaryspecifyothersources: false,
      nonmonetaryspecifyothersources: false,
      specifyothercoverage: false,
      specifyotherveteranscoverage: false,
      employer: false,
    });
    const isApplicantAutocorrecting = ref<{ [key: string]: boolean }>({
      first_name: false,
      last_name: false,
      middle_name: false,
      address1: false,
      address2: false,
      city: false,
      firstname: false,
      lastname: false,
      landlordname: false,
      landlordaddress: false,
      landlordcity: false,
      lendername: false,
      lenderaddress: false,
      lendercity: false,
      utilityprovidername: false,
      utilityprovideraddress: false,
      utilityprovidercity: false,
      firstName: false,
      lastName: false,
      monetaryspecifyothersources: false,
      nonmonetaryspecifyothersources: false,
      specifyothercoverage: false,
      specifyotherveteranscoverage: false,
      employer: false,
    });

    const autoCorrectFields = () => {
      return [
        'first_name',
        'middle_name',
        'last_name',
        'address1',
        'address2',
        'city',
        'firstname',
        'lastname',
        'landlordname',
        'landlordaddress',
        'landlordcity',
        'lendername',
        'lenderaddress',
        'lendercity',
        'utilityprovidername',
        'utilityprovideraddress',
        'utilityprovidercity',
        'firstName',
        'lastName',
        'monetaryspecifyothersources',
        'nonmonetaryspecifyothersources',
        'specifyothercoverage',
        'specifyotherveteranscoverage',
        'employer',
      ];
    };

    const chosenEmailAddress = ref('');
    const useServiceArea = useServiceAreaStore();
    const currentApplicant = ref<Applicant>(JSON.parse(JSON.stringify(emptyApplicant.value)));
    const userApplicant = ref<Applicant[]>([]);
    const suffixChoices = ref([
      { value: '', label: 'None' },
      { value: 'II', label: 'II' },
      { value: 'III', label: 'III' },
      { value: 'IV', label: 'IV' },
      { value: 'Jr', label: 'Jr' },
      { value: 'Sr', label: 'Sr' },
    ]);
    const getCountiesForZipCode = (zipCode: string) => {
      const config = useRuntimeConfig();
      return $fetch<{ success: boolean; data: { county: string; latitude: number; longitude: number }[] }>(
        `/counties/${zipCode}`,
        {
          ...globalFetchOptions(),
          baseURL: config.public.legacyBaseApi,
          method: 'get',
        }
      );
    };
    const countryCodeChoices = ref([{ itemValue: '1', itemText: '1', selected: true }]);
    const createRequestOutput = ref<CreateRequestOutput>();
    const stepFormValues = ref<StepFormValues[]>([]);
    const startOverModal = ref(false);
    const setStartOverModal = (value: boolean) => {
      startOverModal.value = value;
    };
    const setApplicant = (applicant: Applicant | undefined) => {
      if (applicant) {
        currentApplicant.value = JSON.parse(JSON.stringify(applicant));
        // currentApplicant.value.phone_number = applicant.phones.length ? applicant.phones[0].phone_number : '';
        // currentApplicant.value.phone_opted_in = applicant.phones.length ? applicant.phones[0].phone_opted_in : false;
        // currentApplicant.value.phone_validated = applicant.phones.length ? applicant.phones[0].phone_validated : false;

        currentApplicant.value.address1 = applicant.addresses.length ? applicant.addresses[0].address1 : '';
        currentApplicant.value.address2 = applicant.addresses.length ? applicant.addresses[0].address2 : '';
        currentApplicant.value.city = applicant.addresses.length ? applicant.addresses[0].city : '';
        currentApplicant.value.latitude = applicant.addresses.length ? applicant.addresses[0].latitude : 0;
        currentApplicant.value.longitude = applicant.addresses.length ? applicant.addresses[0].longitude : 0;
        currentApplicant.value.county = applicant.addresses.length ? applicant.addresses[0].county : '';
        currentApplicant.value.latitude = applicant.addresses.length ? applicant.addresses[0].latitude : 0;
        currentApplicant.value.longitude = applicant.addresses.length ? applicant.addresses[0].longitude : 0;

        currentApplicant.value.zipcode =
          useServiceArea.zipcodeData.zipcode === ''
            ? applicant.addresses.length
              ? applicant.addresses[0].zipcode
              : ''
            : useServiceArea.zipcodeData.zipcode;
        currentApplicant.value.state =
          useServiceArea.zipcodeData.state === ''
            ? applicant.addresses.length
              ? applicant.addresses[0].state
              : ''
            : useServiceArea.zipcodeData.state;
      }
    };
    const newApplicant = () => {
      currentApplicant.value = JSON.parse(JSON.stringify(emptyApplicant.value));
      currentApplicant.value.id = uuidv4();
      currentApplicant.value.zipcode = useServiceArea.zipcodeData.zipcode;
      currentApplicant.value.state = useServiceArea.zipcodeData.state;
    };
    const setApplicantList = (applicants: Applicant[]) => {
      userApplicant.value = applicants;
    };

    const setCreateRequestOutput = (output: CreateRequestOutput | undefined) => {
      createRequestOutput.value = output;
    };

    const setStepFormValues = (index: number, steps: StepFormValues) => {
      stepFormValues.value[index] = steps;
    };

    const setStepFormValuesNew = (index: number, steps: StepFormValues) => {
      const stepIndex = stepFormValues.value.findIndex((step) => step.stepId === steps.stepId);
      index = stepIndex > -1 ? stepIndex : stepFormValues.value.length;
      stepFormValues.value[index] = steps;
    };

    const clear = () => {
      currentApplicant.value = JSON.parse(JSON.stringify(emptyApplicant.value));
      userApplicant.value = [];
      createRequestOutput.value = undefined;
      stepFormValues.value = [];
      isRedirectToAppointment.value = false;
    };

    const clearBack = () => {
      currentApplicant.value = JSON.parse(JSON.stringify(emptyApplicant.value));
      createRequestOutput.value = undefined;
      stepFormValues.value = [];
    };

    const clearStartOver = () => {
      createRequestOutput.value = undefined;
      stepFormValues.value = [];
    };

    const setChosenEmailAddress = (emailAddress: string) => {
      chosenEmailAddress.value = emailAddress;
    };

    const eraseAssistanceRequestData = (requestIndex: number) => {
      if (currentApplicant.value.applications[requestIndex]) {
        currentApplicant.value.applications.map((app) => {
          app.assistance_requests[requestIndex].formkit_answers.steps.forEach((answer) => {
            answer.data = [];
          });
        });
      }
    };

    const deleteAssistanceRequest = (id: string) => {
      currentApplicant.value.applications = currentApplicant.value.applications.filter((app) => {
        return app.id !== id;
      });
    };

    const addPhone = (phone: ApplicantPhone) => {
      currentApplicant.value.phones.push(phone);
    };

    const removePhone = () => {
      currentApplicant.value.phones.splice(0, 1000);
    };

    const getEmailChangeRequest = () => {
      return $fetch<{ success: boolean; data: unknown }>(`/applicants/auth/request-email-change`, {
        ...globalFetchOptions(),
        method: 'GET',
      });
    };

    const clearAutoCorrectedApplicant = () => {
      autocorrectedApplicant.value = JSON.parse(
        JSON.stringify({
          first_name: false,
          last_name: false,
          middle_name: false,
          address1: false,
          address2: false,
          city: false,
          firstname: false,
          lastname: false,
          landlordname: false,
          landlordaddress: false,
          landlordcity: false,
          lendername: false,
          lenderaddress: false,
          lendercity: false,
          utilityprovidername: false,
          utilityprovideraddress: false,
          utilityprovidercity: false,
          firstName: false,
          lastName: false,
          monetaryspecifyothersources: false,
          nonmonetaryspecifyothersources: false,
          specifyothercoverage: false,
          specifyotherveteranscoverage: false,
          employer: false,
        })
      );
    };
    const clearIsApplicantAutocorrecting = () => {
      autocorrectedApplicant.value = JSON.parse(
        JSON.stringify({
          first_name: false,
          last_name: false,
          middle_name: false,
          address1: false,
          address2: false,
          city: false,
          firstname: false,
          lastname: false,
          landlordname: false,
          landlordaddress: false,
          landlordcity: false,
          lendername: false,
          lenderaddress: false,
          lendercity: false,
          utilityprovidername: false,
          utilityprovideraddress: false,
          utilityprovidercity: false,
          firstName: false,
          lastName: false,
          monetaryspecifyothersources: false,
          nonmonetaryspecifyothersources: false,
          specifyothercoverage: false,
          specifyotherveteranscoverage: false,
          employer: false,
        })
      );
    };
    const autoCorrectCurrentApplicant = (key: string, value: string) => {
      autoCorrectModalOpen.value = true;
      (currentApplicant.value as any)[key] = value;
      (autocorrectedApplicant.value as any)[key] = true;
    };
    const resetAutoCorrect = () => {
      autoCorrectModalOpen.value = false;
      resetAutoFields();
    };
    const resetAutoFields = () => {
      clearAutoCorrectedApplicant();
      clearIsApplicantAutocorrecting();
    };

    const updateIsApplicantAutocorrecting = (key: string, value: boolean) => {
      (isApplicantAutocorrecting.value as any)[key] = value;
      setTimeout(() => {
        (isApplicantAutocorrecting.value as any)[key] = false;
      }, 3000);
    };

    const editApplicant = (id: string) => {
      return useMutation((formData: Applicant) =>
        $fetch<SavePersonalDetails>(`/applicants/${id}`, {
          ...globalFetchOptions(),
          method: 'PUT',
          body: formData,
          retry: false,
        })
      );
    };

    const isRedirectToAppointment = ref(false);
    const setIsRedirectToAppointment = (value: boolean) => {
      isRedirectToAppointment.value = value;
    };

    return {
      emptyApplicant,
      currentApplicant,
      suffixChoices,
      countryCodeChoices,
      userApplicant,
      createRequestOutput,
      stepFormValues,
      setApplicant,
      newApplicant,
      setApplicantList,
      setCreateRequestOutput,
      setStepFormValues,
      clear,
      setChosenEmailAddress,
      chosenEmailAddress,
      eraseAssistanceRequestData,
      deleteAssistanceRequest,
      setStepFormValuesNew,
      clearStartOver,
      clearBack,
      addPhone,
      removePhone,
      startOverModal,
      setStartOverModal,
      getCountiesForZipCode,
      getEmailChangeRequest,
      autocorrectedApplicant,
      autoCorrectCurrentApplicant,
      clearAutoCorrectedApplicant,
      editApplicant,
      autoCorrectModalOpen,
      isApplicantAutocorrecting,
      updateIsApplicantAutocorrecting,
      resetAutoCorrect,
      autoCorrectFields,
      resetAutoFields,
      isRedirectToAppointment,
      setIsRedirectToAppointment,
    };
  },
  {
    persist: true,
  }
);
